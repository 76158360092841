body {
  margin: 0;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  background: #0e0e0e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Global */

.red {
  color: #e30717;
}

.light-bg {
  background: #fff;
}

/* Navbar */

.navbar-light {
  background: #000;

}

.nav-logo {
  width: 35%;
}

.anchor-tag {
  position: absolute;
  left: 0;
  top: -100px;
  scroll-behavior: smooth;
}

.anchor {
  position: relative;
}

.navbar-top {
  padding: 2%;
  height: 100px;
}

.navbar-brand {
  max-width: 20%;
  margin-right: 8%;
  margin-left: 5%;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-top.scrolled {
  background-color: #000;
  transition: background-color 200ms linear;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.mobile-open {
  background-color: #000;
}

.nav-link {
  font-size: 18px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbar-expand-lg .navbar-nav .nav-link.scrolled-link {
  color: #909294;
}

.navbar-brand.scrolled-link {
  color: #000;
}

.navbar-expand-lg .navbar-nav .nav-link.scrolled-link:hover {
  color: #fff;
}

.selected {
  color: #000;
}

.hide {
  display: none;
}

.navbar-toggler {
  border: 0;
}

.fa-bars {
  font-size: 2em;
}

.white-burger {
  color: #fff;
}

.black-burger {
  color: #000;
}

.mobile-nav {
    background-color: #fff;
    height: auto;
}

.btn {
  font-size: 1em;
  margin-right: 10px;
}

.btn-donate {
  background-color: transparent;
  border: solid 1px #00dcff;
  color: #00dcff;

}

.btn-donate-light {
  background-color: transparent;
  border: solid 1px #00dcff;
  color: #00dcff;
}

.btn-donate:hover {
  background-color: #00dcff;
  color: #000;
}

.btn-donate-light:hover {
  background-color: #00dcff;
  color: #000;
}

.btn-gig {
  background-color: transparent;
  border: 1px solid #1eed4d;
  color: #1eed4d;
}

.btn-gig:hover {
  background-color: #1eed4d;
  color: #000;
}

.nav-button {
  background: transparent;
  border: none;
  font-weight: 300;
}

.nav-button:hover {
  cursor: pointer;
}

.nav-button:focus {
  outline: none;
}

/* Header */

header  {
  height: 100vh;
  min-height: 400px;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top;
  background-position-x: left;
  background-image:
    url('./images/ewan-header.jpeg');
  background-color: #010101;
}

.header-text {
  width: 50%;
  text-align: right;
  margin-left: 50%;
  padding-top: 40vh;
  color: #fff;

}

.header-text h1 {
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 2px;
}

.header-buttons button {
  width: 45%;
}

.music {
  padding: 25px;
}

/* General */
.line {
  position: relative;
  overflow: visible;
  height: 1px;
  border-collapse: collapse;
  border: 0.5px solid;
}

.logo {
  height: 50px;
}

/* What We Do */

.what-we-do {
  position: relative;
  letter-spacing: 2px;
  line-height: 2;
  min-height: 87vh;
  padding: 25px;
  color: #333;
  background: #fff;
  /* background-image: linear-gradient(
    rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.65)
    ),
    url('./images/what-we-do.jpg');
  background-size: cover;
  background-repeat: no-repeat; */
}

.band-img {
  max-width: 100%;
}

.what-we-do-text {
  text-align: center;
  padding-top: 5vh;
}

.what-we-do-text p {
  font-size: 20px;
  font-weight: 500;
}

.what-we-do .subheading {
  margin-top: 25px;
}


/* Awards */

.awards {
  position: relative;
  letter-spacing: 2px;
  line-height: 2;
  padding: 25px;
  min-height: 87vh;
  color: #fff;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65)
      ),
    url('./images/mission.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.band-img {
  max-width: 100%;
}

.awards-text {
  text-align: center;
  padding-top: 5vh;
}

.awards-text p {
  font-size: 20px;
  font-weight: 500;
}

.btn-bursary, .btn-workshop {
  margin-top: 50px;
  margin-bottom: 50px;
  background: transparent;
  color: #fff;
  white-space: normal;
}

.btn-bursary h3, .btn-workshop h3 {
  font-size: 50px;
}

.btn-workshop:hover > p {
  text-decoration: underline;
}

.btn-bursary:hover > p {
  text-decoration: underline;
}

/* Events */

.events {
  position: relative;
  letter-spacing: 2px;
  line-height: 2;
  min-height: 87vh;
  padding: 25px;
  color: #333;
  background: #fff;
  /* background-image: linear-gradient(
    rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.65)
    ),
    url('./images/what-we-do.jpg');
  background-size: cover;
  background-repeat: no-repeat; */
}

.event-text {
  text-align: center;
  padding-top: 5vh;
}

.event-text p {
  font-size: 20px;
  font-weight: 500;
}

/* Modals */

#gigModal .modal-dialog {
  max-width: 75%;
}

#gigModal .modal-header {
  color: #fff;
  height: 60vh;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
      ),
    url('./images/gigModal.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

#gigModal .modal-header h5 {
  font-size: 60px;
  align-self: center;
  margin: auto;
}

#gigModal {
  text-align: left;
}

#gigModal p {
  font-size: 20px;
}

#workshopModal .modal-dialog {
  max-width: 75%;
}

#workshopModal .modal-header {
  color: #fff;
  height: 60vh;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
      ),
    url('./images/workshop.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

#workshopModal .modal-header h3 {
  font-size: 50px;
  align-self: center;
  margin: auto;
}

#workshopModal .modal-body p {
  font-size: 25px;
  margin-bottom: 25px;
}

#workshopModal .modal-body ul {
  padding-left: 0;
}

#workshopModal .modal-body li {
  font-size: 20px;
  list-style: none;
  padding: 15px 0;
}


#bursaryModal .modal-dialog {
  max-width: 75%;
}

#bursaryModal .modal-header {
  color: #fff;
  height: 60vh;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
      ),
    url('./images/bursary.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

#bursaryModal .modal-header h3 {
  font-size: 50px;
  align-self: center;
  margin: auto;
}

#bursaryModal .modal-body h3 {
  font-size: 25px;
  margin-bottom: 25px;
}

#bursaryModal p {
  font-size: 20px;
  text-align: left;
  padding-left: 25px;
}

#bursaryModal .modal-body ul {
  text-align: left;
  padding-left: 75px;
}

#bursaryModal .modal-body li {
  font-size: 20px;
  padding: 15px 0;
}

#contactModal .modal-dialog {
  max-width: 75%;
}

#contactModal h3 {
  margin-bottom: 25px;
}

#contactModal p {
  font-size: 20px;
}

.close-modal {
  background: transparent;
  border: none;
  color: #fff;
}

.close-modal:hover {
  background: transparent;
  border: none;
  color: #fff;
}

.close-modal-dark {
  background: transparent;
  border: none;
  color: #000;
}

.close-modal-dark:hover {
  background: transparent;
  border: none;
  color: #000;
}

/* Community */

.community {
  padding-top: 150px;
}

.community h1 {
  color: #000;
}

.login-register {
  text-align: right;
}

.post-section {
  text-align: left;
  color: #000;
  line-height: 2;
  letter-spacing: 2px;
}

.posts {
  border-right: 2px solid #fff;
  overflow: scroll;
  height: 70vh;
}

.post {
  padding: 25px 10px;
}

.post .music {
  padding: 0;
}

.event {
  padding: 25px 0;
}

/* Contact */

.contact-button {
  font-size: 20px;
}

/* Footer */


.footer {
  text-align: left;
  height: 300px;
  padding-top: 100px;
  color: #bcbcbc;
}

.navbar-bottom {
  border-top: 1px solid;
  margin-top: 20px;
}

.navbar-bottom .navbar-nav {
  flex-direction: row;
}

.navbar-bottom .nav-link {
  padding: 10px 20px;
  font-size: 18px;
  color: #bcbcbc;
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .header-text {
    margin-left: 30%;
    width: 70%;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 1115px) {
  .navbar-brand {
    margin-right: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
    background: #000;
    padding-bottom: 15px;
  }

  .navbar-top {
    padding: 0
  }
  .header-text {
    margin-left: 15%;
    width: 85%;
  }
}

@media only screen and (max-width: 840px) {
  header {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.65),
        rgba(0, 0, 0, 0.65)
        ),
      url('./images/ewan-header.jpeg');
  }
}

@media only screen and (max-width: 767px) {

  .header-text {
    margin-left: 0;
    width: 100%;
  }
  .header-text h1 {
    font-size: 2rem;
  }
  .music {
    padding: 15px;
  }
  #workshopModal .modal-dialog {
    max-width: 100%;
  }
  #bursaryModal .modal-dialog {
    max-width: 100%;
  }
  #contactModal .modal-dialog {
    max-width: 100%;
  }
}

@media only screen and (max-width: 440px) {
  .navbar-bottom .navbar-nav {
    flex-direction: column;
  }

  .nav-logo {
    width: 60%;
  }
}



@media only screen and (max-height: 740px) {
  .what-we-do-text p {
    font-size: 18px;
  }

  .mission-text p {
    font-size: 18px;
  }
}

/* Animations */

.vibrate-3 {
	-webkit-animation: vibrate-3 0.5s linear infinite both;
	        animation: vibrate-3 0.5s linear infinite both;
}

.wobble-ver-left {
	-webkit-animation: wobble-ver-left 0.8s infinite both;
	        animation: wobble-ver-left 0.8s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-8 21:13:49
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-3
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-8 21:55:9
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
  }
  30% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
  }
  45% {
    -webkit-transform: rotate(-3.6deg);
            transform: rotate(-3.6deg);
  }
  60% {
    -webkit-transform: rotate(2.4deg);
            transform: rotate(2.4deg);
  }
  75% {
    -webkit-transform: rotate(-1.2deg);
            transform: rotate(-1.2deg);
  }
}
@keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
  }
  30% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
  }
  45% {
    -webkit-transform: rotate(-3.6deg);
            transform: rotate(-3.6deg);
  }
  60% {
    -webkit-transform: rotate(2.4deg);
            transform: rotate(2.4deg);
  }
  75% {
    -webkit-transform: rotate(-1.2deg);
            transform: rotate(-1.2deg);
  }
}
